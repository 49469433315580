<template>
  <div class="international">
    <BannerKv
      :titleEn="BannerKvData.titleEn"
      :titleZh="BannerKvData.titleZh"
      :backgroundPic="BannerKvData.backgroundPic"/>

    <section id="top_point">
      <div class="container w1000">
        <div class="inner">
          <div class="item">
            <div>
              <p class="small txt-white">{{$t('visaProcess')}}</p>
              <h6 class="txt-white">{{$t('professional')}}</h6>
            </div>
          </div>
          <div class="item">
            <div>
              <p class="small txt-white">{{$t('oneForOne')}}</p>
              <h6 class="txt-white">{{$t('personService')}}</h6>
            </div>
          </div>
          <div class="item">
            <div>
              <p class="small txt-white">{{$t('medicalService')}}</p>
              <h6 class="txt-white">{{$t('englishService')}}</h6>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="step">
      <div class="w1200">
        <div class="step_box">
          <div class="tagset">
            <div class="tag h6"
              :class="{active:NowStep==0}"
              @click="scrollToStep($refs.step0, 0);">
              STEP 1. {{$t('contactUs')}}
            </div>
            <div class="tag h6"
              :class="{active:NowStep==1}"
              @click="scrollToStep($refs.step1, 1);">
              STEP 2. {{$t('readyDocuments')}}
            </div>
            <div class="tag h6"
              :class="{active:NowStep==2}"
              @click="scrollToStep($refs.step2, 2);">
              STEP 3. {{$t('provideDocuments')}}
            </div>
          </div>
        </div>
        <div class="infobox" ref="step_infobox">
          <div class="info_item"
            v-for="(item, key) in StepSection"
            :key="key"
            :id="`step${key}`"
            :ref="`step${key}`"
            :style="{backgroundImage: `url(${item.bgPic})`}">
            <div class="content">
              <p class="content_title">{{item.contentTitle}}</p>

              <div class="text">
                <h6 class="txt-bold">{{item.midTitle}}</h6>
                <div class="line"></div>
                <p class="small txt-dark_gray" v-html="item.info">
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="treatment">
      <div class="w800 txt-center">
        <h3 class="eng">{{info.title}}</h3>
        <h5>{{info.sub_title}} <img src="@/assets/img/deco_flower.svg" alt="" class="deco_flower"></h5>
        <br>
        <p class="txt-dark_gray small">
          {{info.content}}
        </p>
      </div>
      <div class="container w1000">
        <!-- <div class="item">
          <h6 class="txt-bold">申請簽證</h6>
          <p class="small txt-theme">(中國大陸地區居民除外)</p>
          <br>
          <p class="small">
            觀光簽證、商務簽證入境（簽證效期為7-30日）
          </p>
          <p class="small txt-dark_gray">
            1.若須超過一個月則需申請醫療簽證<br>
            2.簽證延期：診斷證明書或治療計畫書
          </p>
          <a href="" class="download small" target="_blank" rel="noopener noreferrer" download>
            <img src="@/assets/img/Entry_Permit_Application/icon_1.svg" alt="">
            醫療簽證流程
          </a>
        </div>
        <div class="item">
          <h6 class="txt-bold">中國大陸地區居民申請醫療入台證說明</h6>
          <p class="small txt-theme">(僅限中國大陸地區居民)</p>
          <br>
          <p class="small">
            就醫與隨行照料(伴醫)
          </p>
          <p class="small txt-dark_gray">
            1.每次最長可停留3個月，可以延期，每次最多2個月。<br>
            2.入台證至種類為逐次加簽，效期1-3年，每次辦理加簽後可以再次來台。<br>
            3.隨行照料親屬:可允許2位三等親同時申請。
          </p>
          <a href="" class="download small" target="_blank" rel="noopener noreferrer" download>
            <img src="@/assets/img/Entry_Permit_Application/icon_1.svg" alt="">
            來臺就醫流程
          </a>
        </div> -->
        <div class="item" v-for="(item, key) in items" :key="key">
          <h6 class="txt-bold">{{item.title}}</h6>
          <p class="small txt-theme">{{item.sub_title}}</p>
          <br>
          <p class="small">
            {{item.brief_info}}
          </p>
          <p class="small txt-dark_gray" v-for="(list, key) in item.item" :key="key">
            {{list}}
          </p>
          <a class="download small" target="_blank" rel="noopener noreferrer" :href="item.file" download>
            <img src="@/assets/img/Entry_Permit_Application/icon_1.svg" alt="">
            {{item.file_title}}
          </a>
        </div>
        <!-- <div class="item">
          <h6 class="txt-bold">申請入台證</h6>
          <p class="small txt-theme">(僅限中國大陸地區居民)</p>
          <br>
          <p class="small">
            來台需同時有台灣及中國大陸兩方證件
          </p>
          <p class="small txt-dark_gray">
            1.證件：中華民國台灣地區出入境許可證(入台證)與大陸居民往來台灣通行證(大通證)或港澳通行證。<br>
            2.中國大陸地區申請來台目的：探親、團聚、依親、自由行等，可自行來台。<br>
            3.宜蘊可受理協助辦理：醫療入台證
          </p>
        </div> -->
      </div>
    </section>

    <section id="ready"
      :style="{backgroundImage: `url(${require('@/assets/img/Entry_Permit_Application/bg.jpg')})`}">
      <div class="text txt-white txt-center">
        <h5 style="margin-bottom: 10px;">
          {{$t('contactUsAndComing')}}
        </h5>
        <h6 class="eng">
          ARE YOU READY
          <img src="@/assets/img/deco_flower_white.svg" alt="" class="deco_flower">
        </h6>
        <!-- <div class="btn btn-white" style="margin-top: 30px;">立即申請</div> -->
      </div>
    </section>

    <section id="client">
      <div class="client_intro w1200">
        <div class="col50">
          <!-- <h3 class="eng">ONE-ON-ONE CLIENT SERVICE</h3> -->
          <h5 class="txt-bold">
            {{$t('oneForOneService')}}
            <img class="deco_flower" src="@/assets/img/deco_flower.svg" alt="">
          </h5>
        </div>
        <div class="col50">
          <p class="small">
            {{$t('oneForOneServiceContent')}} <br>
            <span class="txt-theme">({{$t('thisIsNotInsurance')}})</span>
          </p>
          <a href="mailto:ivfconsult@nuwacare.com" class="btn"
          :class="[getLanguage === 'en-US' ? 'btnEn' : '']"
          >{{$t('reservation')}}</a>
        </div>
      </div>
    </section>
  </div>

</template>

<script>
import '@/assets/scss/international.scss';
import BannerKv from '@/components/BannerKv.vue';

import pic01 from '@/assets/img/Entry_Permit_Application/bg.jpg';
import stepSectionBg01 from '@/assets/img/NUWA_Baby/1.jpg';
import stepSectionBg02 from '@/assets/img/temp/a11.jpg';
import stepSectionBg03 from '@/assets/img/temp/a12.jpg';

import { getDocuments, getInternational } from '@/lib/public';
import { mapMutations, mapGetters } from 'vuex';

export default {
  name: 'International',
  components: {
    BannerKv,
  },
  data() {
    return {
      items: [],
      info: {
        title: 'Treatment Introduction',
        sub_title: this.$t('dealVisaOrEntry'),
        content: this.$t('dealVisaOrEntryContent'),
      },
      BannerKvData: {
        titleEn: 'ENTRY PERMIT APPLICATION',
        titleZh: this.$t('dealVisaAndSteps'),
        backgroundPic: pic01,
      },
      NowStep: 0,
      StepSection: [
        {
          bgPic: stepSectionBg01,
          contentTitle: `STEP 1. ${this.$t('contactUs')}`,
          midTitle: this.$t('stepOneMidTitle'),
          info: this.$t('stepOneInfo'),
        },
        {
          bgPic: stepSectionBg02,
          contentTitle: `STEP 2. ${this.$t('readyDocuments')}`,
          midTitle: this.$t('stepTwoeMidTitle'),
          info: `${this.$t('stepTwoInfoParam1')}<br><br>
                <ol class="numList">
                <li><span style="color: #AF566D">${this.$t('stepTwoInfoParam2')}</span></li>
                <li><span style="color: #AF566D">${this.$t('stepTwoInfoParam3')}</span></li>
                <li><span style="color: #AF566D">${this.$t('stepTwoInfoParam4')}</span></li>
                <ol>`,
        },
        {
          bgPic: stepSectionBg03,
          contentTitle: `STEP 3. ${this.$t('provideDocuments')}`,
          midTitle: this.$t('stepThreeeMidTitle'),
          info: `${this.$t('stepThreeInfoParam1')}E-mail <a href="mailto:ivfconsult@nuwacare.com">ivfconsult@nuwacare.com</a> 。<br>${this.$t('stepThreeInfoParam2')}<br><br>
                <ol class="numList">
                <li><span style="color: #AF566D">${this.$t('stepThreeInfoParam3')}</span></li>
                <li><span style="color: #AF566D">${this.$t('stepThreeInfoParam4')}</span></li></ol>`,
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'getLangUUID',
      'getLanguage',
    ]),
  },
  methods: {
    ...mapMutations([
      'setLangUUID',
    ]),
    scrollToStep(elem, nextNowStep) {
      this.NowStep = nextNowStep;
      this.$refs.step_infobox.scrollTo({
        top: elem[0].offsetTop,
        behavior: 'smooth',
      });
    },
    getData() {
      getDocuments(
        this.getLangUUID,
        (response) => {
          response.data.data.forEach((elem) => {
            const temp = {
              uuid: elem.uuid,
              title: elem.title,
              sub_title: elem.sub_title,
              brief_info: elem.brief_info,
              item: (elem.items !== null) ? JSON.parse(elem.items) : [''],
              file_title: elem.file_title,
              file: elem.file,
            };
            this.items.push(temp);
          });
        },
      );
    },
    getInfo() {
      getInternational(
        this.getLangUUID,
        (res) => {
          if (res.data.status) {
            this.info = res.data.data;
          }
        },
      );
    },
  },
  watch: {
    getLangUUID(val) {
      if (val !== null) {
        this.getData();
        this.getInfo();
      }
    },
  },
  mounted() {
    if (this.getLangUUID !== null) {
      this.getData();
      this.getInfo();
    }
  },
};
</script>
